#Menu {
    height: auto;
    background-position: center;
    background-size: cover;
    padding-bottom: 3vh;
}
.MenuContent {
    margin-left: 3rem;
    border-width: 3px 0px 0px 3px;
    border-style: double;
    height: auto;
    width: 90%;
    font-family: Nova Oval;
    justify-content: center;
}
h5 {
    padding-left: 3rem;
    margin-top: 2%;
    margin-bottom: 2.5rem;
    font-size: 5rem;
    width: 100vw;
    letter-spacing: 3px;
    font-weight: 900;
}
h6 {    
    color: #ff3333;
    margin-top: 1vh;
    margin-bottom: 2.5rem;
    font-size: 5rem;
    letter-spacing: 3px;
    font-weight: 900;
    text-align: center;
}
.MenuList {
    text-align: center;
    padding: 0;
    margin: 0;
}
.menuTitle {
    font-size: 2rem;
    margin-bottom: 2rem;
    font-weight: 400;
    font-weight: 900;
    margin: 0;
}
.menuPrice {
    font-size: 1rem;
    margin: 0 0 20px 0;
}
.menuAddOnContainer {
    display: flex;
}
.MenuList {
    list-style-type: none;
}

@media screen and (max-width: 650px) {
    .MenuContent {
        margin-left: 0.5rem;
    }
    h5 {
        padding-left: 0.5rem;
        font-size: 3.5rem;
        margin-bottom: 2rem;
    }
    h6 {
        padding-left: 0.5rem;
        font-size: 3rem;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
    }
    .menuTitle {
        font-size: 1.5rem;
    }
}