.SidebarContainer {
    display: none;
}
@media screen and (max-width: 650px) {
    .SidebarContainer {
        right: 0px;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.6)), darkred;
        text-transform: uppercase;
        position: absolute;
        height: 355px;
        display: grid;
        width: 0vw;
        font-size: 2rem;
        letter-spacing: 3px;
        font-weight: 700;        
        margin-top: 0px;
        margin-left: 0px;
        transition: width 0.5s;
    }
    .SidebarLink {
        height: 60px;
        width: 75vw;
        text-align: left;
        pointer-events: none;
        padding-top: 10px;
        padding-left: 10px;
        border-bottom: 1px solid yellow;
    }
    a > div {
        color: white;
    }
    a > div:hover {
        cursor: pointer;
    }
}
