#WelcomeContainer {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)), url('../../images/PeriFoods.png');
    height: 100vh;
    width: 100%;
    background-position: center;
    background-size: cover;
}
.WelcomeItems {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
    max-height: 100%;
    padding: 0 2rem 0 3rem;
    width: 650px;
    color: #fff;
    line-height: 1;
    font-weight: 700;    
}
.Heroh1 {
    font-size: 6rem;
    margin-bottom: 1rem;
    box-shadow: 3px 5px #e9ba23;
    letter-spacing: 3px;
    font-weight: 1000;
}
.Herop {
    font-size: 3rem;
    margin: 0rem;
}
@media screen and (max-width: 650px) {
    .WelcomeItems {
        width: 85%;
        padding-left: 1rem;
    }

    .Heroh1 {
        font-size: 3rem;
    }

    .Herop {
        font-size: 2rem;
    }
}