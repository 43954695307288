.SidebarIcon {
    display: none;
}

.SidebarIcon:hover {
    cursor: pointer;
}

.SidebarIcon > div {
    height: 5px;
    background: #fff;
    margin: 12px 0px 10px 0px;
    border-radius: 25px;
}

@media screen and (max-width: 650px) {
    .SidebarIcon {
        display: block;
        height: 53px;
        width: 50px;
        margin: 30px 40px;
    }
    .SidebarIcon > div {
        margin: 10px 0px 10px 0px;
    }
}