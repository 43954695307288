#Promo {
    height: 50vh;
    width: 100%;
    background-position: center;
    background-size: cover;
}
.PromoContent {
    padding-left: 3rem;
}
.PromoItems {
    max-height: 100%;
}
h2 {
    padding-left: 3rem;
    margin-top: 2%;
    font-size: 5rem;
    margin-bottom: 1rem;
    letter-spacing: 3px;
    font-weight: 900;
}
.PromoLi {
    font-size: 2rem;
    margin-bottom: 2rem;
    font-weight: 400;
}
.NoteP {
    font-size: 1.5rem;
    font-style: italic;
    font-weight: 400;
}
@media screen and (max-width: 650px) {
    #Promo {
        height: 68vh;
    }

    .PromoContent {
        padding-left: 0.5rem;
    }

    h2 {
        font-size: 3.5rem;
        padding-left: 0.5rem;
    }

    .PromoLi, .NoteP {
        font-size: 1.5rem;
    }
}
@media screen and (max-height: 800px) {
    #Promo {
        height: 85vh;
    }
}