#Franchise {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)), url('../../images/PeriPoint.png');
    height: 150vh;
    width: 100%;
    color: white;
    background-position: center;
    background-size: cover;
}
.FranchiseContent {
    padding: 3rem 0 15rem 3rem ;
}
h7 {
    padding-left: 3rem;
    margin-top: 2%;
    font-size: 5rem;
    width: 100vw;
    letter-spacing: 3px;
    font-weight: 900;
}

@media screen and (max-width: 650px) {
    #Franchise {
        height: auto;
    }

    .FranchiseContent {
        padding: 0 0 6rem 0;
    }

    h7 {
        padding-left: 0.5rem;
        font-size: 3.5rem;
        margin-bottom: 2rem;
    }
}
