#FooterContainer {
    height: 55vh;
    width: 100%;
    background-position: center;
    background-size: cover;
    display: flex;
}

.FooterItems {
    max-height: 100%;
    width: 100%;
    display: grid;    
}
.fblogo, .iglogo, .skiplogo, .ddlogo, .uelogo {
    width: 100px;
    height: auto;
    object-fit: cover;
    border-radius: 15px;
}
#OrderNowLinks {
    text-align: center;
    color: gold;
    margin-bottom: 5vh;
}
.socialMediaLinks {
    text-align: center;
    color: gold;
    margin-bottom: 25vh;
}

.footerP {
    margin: 0;
    font-size: 5rem;
    letter-spacing: 3px;
    font-weight: 900;
}

@media screen and (max-width: 650px) {
    .fblogo, .iglogo, .skiplogo, .ddlogo, .uelogo {
        width: 50px;
        height: auto;
    }
    .footerP {
        font-size: 3rem;
        letter-spacing: 3px;
        font-weight: 700;
    }
}