.NavbarContainer {
    position: sticky;
    top: 0;
    background-color: white;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.NavbarMenu {    
    position: absolute;
    width: 75vw;
    display: flex;    
    margin: 30px 5px 5px 10px;    
    justify-content: center;
}
.ImgContainer {
    position: absolute;
    height: 60px;
    width: 100%;
    justify-content: center;
}
.NavImg {    
    width: auto;
    height: 90px;
    object-fit: cover;
    border-radius: 0 0 50px 50px;
    margin-top: -25px;
}
.NavLeft {
    display: flex;
    right: 50%;
    margin-top: -10px;
    margin-right: 1vw;
}
.NavRight {
    display: flex;
    left: 50%;
    margin-top: -10px;
    margin-left: 1vw;
}
a {
    height: 30px;
}
.navButtons {
    text-transform: uppercase;
    height: 30px;
    color: #000066;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 3px;
}
.navButtons:hover {
    box-shadow: 0px 4px #e9ba23;
}
.NavLeft > .NavLink {
    text-align: right;
    margin-right: 20px;
}
.NavRight > .NavLink {
    margin-left: 20px;
}
.NavOrderNow {    
    position: relative;
    top: -25px;
    right: -100px;
    height: 50px;
    background-color: darkred;
    border-color: darkred;
    border-radius: 10px;
    border: none;
}
.navOrderNowButton {
    text-transform: uppercase;
    color: yellow;
    width: 130px;
    height: 30px;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 3px;
    padding: 15px 5px 1px 5px;
}

@media screen and (max-width: 650px) {
    .NavbarContainer {
        position: absolute;
        justify-content: normal;
        background-color: transparent;
        flex-direction: row-reverse;
        box-shadow: none;
    }
    .NavbarMenu, .NavImg, .NavOrderNow {
        display: none;
    }    
}
