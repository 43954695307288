#Locations {
    background-image: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url('../../images/PeriPointSauces.png');
    height: 150vh;
    width: 100%;
    background-position: center;
    background-size: cover;
}
.LocationsItems {
    max-height: 150vh;
    display: grid;
}
h3 {
    padding-left: 3rem;
    margin-top: 2%;
    font-size: 5rem;
    width: 100vw;
    letter-spacing: 3px;
    font-weight: 900;
}
.LocationSection {
    margin-top: -3.5rem;
    height: 150vh;
    display: flex;
    max-width: 95%;
}
.PerLocationContainer {
    margin: 1rem 1rem 0 1rem;
    height: 100vh;
    width: 90%;
    max-width: 100%;
    border-top: ridge;
    border-block-color: brown;
    justify-content: center;
}
img {
    position: center;
    margin: 5vh 1rem 0 1rem;
    height: 70vh;
    width: 45vw;
}
h4 {
    font-size: 5rem;
    margin: 1rem 1rem 0 1rem;
}
p {
    font-size: 3rem;
    margin: 1rem 1rem 0 1rem;
    font-weight: 400;
}
.GetDirection {
    background-color: transparent;
    border: solid;
    border-color: black;
    border-width: 1.5px;
    margin: 5vh 1rem 0 1rem;
    color: black;
    padding: 20px;
    font-weight: 600;
}
button:hover {
    opacity:0.7;
    cursor: pointer;
}
@media screen and (max-width: 650px) {
    #Locations {
        height: 180vh;
    }
    .PerLocationContainer {
        height: 85vh;
    }
    .GetDirection {
        margin-top: 2vh;
    }
    .LocationSection {
        display: grid;
    }
    img {
        margin-top: 3vh;
        height: 50vh;
        width: 80vw;
    }
    h3 {
        padding-left: 0.5rem;
        font-size: 3.5rem;
    }
    h4 {
        font-size: 2rem;
        margin: 1rem 1rem 0 1rem;
    }
    p {
        font-size: 1rem;
        margin: 1rem 1rem 0 1rem;
        font-weight: 500;
    }
}
