.buttonToTop {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;    
    font-size: 18px;
    border: none;
    outline: none;
    background-color: red;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 4px;
    opacity: .3;
}
.buttonToTop:hover {
    opacity: 1;
}
@media screen and (max-width: 650px) {
    .buttonToTop {
        right: unset;
        left: 30px;
    }
}